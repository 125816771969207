import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout-agency'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import ArticlePreview from '../components/article-preview'
import { Card, Grid, Container, Divider, Image } from 'semantic-ui-react'

import heroStyles from '../components/hero.module.css'

const options = {
  renderText: (text) => text.split('\n').reduce((children, textSegment, index) => {
    return [...children, index > 0 && <br key={index} />, textSegment];
  }, [])
};

class BlogPostTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulAgency')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    debugger
    return (
      <Layout location={this.props.location}>
          <Container text>
          <Helmet title={`${post.title} | ${siteTitle}`} />
          <div className="wrapper">
            <h1 className="section-headline">{post.title}</h1>
            <Grid centered stackable columns={2}>
              <Grid.Column>
                <Card>
                  <div style={{padding: '4px'}}>
                    <Img alt="" fluid={post.heroImage.fluid} />
                  </div>
                </Card>
                {/* <ArticlePreview article={post} /> */}
              </Grid.Column>
            </Grid>
            <br></br>
            {documentToReactComponents(post.body.json, options)}
          </div>
        </Container>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulAgency(slug: { eq: $slug }) {
      title
      heroImage {
        fluid(maxWidth: 1180, background: "rgb:000000") {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
      body {
        json
      }
    }
  }
`
