/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import 'semantic-ui-css/semantic.min.css'
import { createMedia } from '@artsy/fresnel'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react'

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
})

/* Heads up!
 * HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled
 * components for such things.
 */
const HomepageHeading = ({ mobile }) => (
  <Container text>
    <Image inline={true} src='http://images.ctfassets.net/2l5gu6vk572p/6egpYys6YAWf7UNyg4NqNy/c7b9976b3cd96481ac0db9ea314adf3d/AdobeStock_101622528.jpg' />
    <Header
      as='h1'
      content='Imagine-a-Company'
      inverted
      style={{
        fontSize: mobile ? '2em' : '4em',
        fontWeight: 'normal',
        marginBottom: 0,
        marginTop: mobile ? '1.5em' : '3em',
      }}
    />
    <Header
      as='h2'
      content='Do whatever you want when you want to.'
      inverted
      style={{
        fontSize: mobile ? '1.5em' : '1.7em',
        fontWeight: 'normal',
        marginTop: mobile ? '0.5em' : '1.5em',
      }}
    />
    <Button primary size='huge'>
      Get Started
      <Icon name='right arrow' />
    </Button>
  </Container>
)

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
}

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends Component {
  state = {fixed: true}

  render() {
    const { children } = this.props
    const { fixed } = this.state

    return (
      <Media greaterThan='mobile'>
        <Visibility
          once={false}
        >
          <Segment
            inverted
            textAlign='center'
            style={{ padding: '1em 0em', position: 'relative', zIndex: 100, width: '100%', background: 'transparent', marginBottom: '20px' }}
            vertical
          >
            <Menu
              fixed={fixed ? 'top' : null}
              inverted={!fixed}
              pointing={!fixed}
              secondary={!fixed}
              borderless={true}
              style={{border: 'none'}}
              size='large'
            >
              <Container>
                <Image href={'/'} hidden={!fixed} fluid={false} style={{paddingTop: '4px', maxWidth: '250px', maxHeight:'70px'}} src='https://images.ctfassets.net/2l5gu6vk572p/3hzliv1V3T9usYsqbe2nhK/8e8c995969bac57c8eda22e36ae96f3a/Summit-Insurance-Group-of-PA-LLC-Logo__1_.png'></Image>
                <Image href={'/'} hidden={fixed} fluid={false} style={{maxWidth: '250px', maxHeight:'70px'}} src='https://images.ctfassets.net/2l5gu6vk572p/18JR3Yd9wkOLyqAAMtR6Q4/13fe32bf3bab4666eb88f89820cf830d/Summit-Insurance-Group-of-PA-LLC-Logo.png'></Image>
                <Menu.Item position='right'>
                    <Menu.Item href={'/#about-us'} as='a'>
                    About Us
                    </Menu.Item>
                    <Menu.Item href={'/#agencies'} as='a'>Agencies</Menu.Item>
                    <Menu.Item href={'/#carriers'} as='a'>Carriers</Menu.Item>
                    <Menu.Item href={'/#membership'} as='a'>Membership Benefits</Menu.Item>
                    <Menu.Item href={'mailto:kshields@shieldsinsurance.com'} as='a'>Contact Us</Menu.Item>
                </Menu.Item>
              </Container>
            </Menu>
          </Segment>
        </Visibility>

        {children}
      </Media>
    )
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
}

class MobileContainer extends Component {
  state = {}

  handleSidebarHide = () => this.setState({ sidebarOpened: false })

  handleToggle = () => this.setState({ sidebarOpened: true })

  render() {
    const { children } = this.props
    const { sidebarOpened } = this.state

    return (
      <Media as={Sidebar.Pushable} at='mobile'>
        <Sidebar.Pushable>
          <Sidebar
            as={Menu}
            animation='overlay'
            inverted
            onHide={this.handleSidebarHide}
            vertical
            visible={sidebarOpened}
          >
            <Menu.Item position='right'>
                <Menu.Item href={'#about-us'} as='a'>
                About Us
                </Menu.Item>
                <Menu.Item as='a'>Agencies</Menu.Item>
                <Menu.Item as='a'>Carriers</Menu.Item>
                <Menu.Item as='a'>Membership Benefits</Menu.Item>
                <Menu.Item as='a'>Contact Us</Menu.Item>
            </Menu.Item>
          </Sidebar>

          <Sidebar.Pusher dimmed={sidebarOpened}>
            <Segment
              inverted
              textAlign='center'
              style={{ padding: '1em 0em' }}
              vertical
            >
              <Container>
                <Menu inverted pointing secondary size='large'>
                  {/* <Menu.Item onClick={this.handleToggle}>
                    <Icon name='sidebar' />
                  </Menu.Item> */}
                  <Image href={'/'} fluid={false} style={{maxWidth: '250px', maxHeight:'70px', margin: 'auto'}} src='https://images.ctfassets.net/2l5gu6vk572p/18JR3Yd9wkOLyqAAMtR6Q4/13fe32bf3bab4666eb88f89820cf830d/Summit-Insurance-Group-of-PA-LLC-Logo.png'></Image>

                </Menu>
              </Container>
            </Segment>

            {children}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Media>
    )
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
}

const ResponsiveContainer = ({ children }) => (
  /* Heads up!
   * For large applications it may not be best option to put all page into these containers at
   * they will be rendered twice for SSR.
   */
  <MediaContextProvider>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </MediaContextProvider>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

export default ResponsiveContainer